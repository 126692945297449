// eslint-disable-next-line import/prefer-default-export
export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            zoneIds
        }
    }
`;

export const listFestivals = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                site
                zone {
                    id
                    name
                }
                state {
                    enabled
                }
                festivalZoneId
            }
            nextToken
        }
    }
`;

export const listApplications = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationFestivalId
                instrument {
                    id
                    name
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        id
                        name {
                            legal
                        }
                    }
                }
                teacher {
                    id
                    name {
                        first
                        last
                    }
                }
                verified
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const updateApplication = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            verified
        }
    }
`;
