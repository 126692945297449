<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Festival Applications" active/>
    </template>

    <template #actions>
      <b-button v-b-tooltip="'Print'" variant="transparent" size="sm" @click="print('table-container')">
        <font-awesome-icon icon="fa-solid fa-print"></font-awesome-icon>
      </b-button>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
        <b-row>
          <b-col>
            <table-layout
                :loading="table.loading"
                :items="table.items"
                :fields="table.fields"
                :filters="table.filters" :filters-options="{ visible: true, collapsed: false }"
                :sorting="table.sorting"
                :empty-text="table.emptyText"
                :export-exclude-fields="[
                        'id',
                        'applicationFestivalId',
                        'instrument.id',
                        'student.id',
                        'student.school.id',
                        'teacher.id'
                ]">

              <template #filters>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-for="festival-input">
                      <template #label>
                        Festival (<span class="text-danger">required</span>)
                      </template>
                      <v-select id="festival-input"
                                v-model="festival"
                                :options="options.festivals.items"
                                :loading="options.festivals.loading"
                                :reduce="option => option.id"
                                label="name"
                                :select-on-tab="true"
                                :clearable="true"
                                :filter="filterFestival"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 mb-1"
                                @input="getApplications">
                        <template #option="{ id, name, site }">
                          <span class="d-block">Festival {{ name }}</span>
                          <small>{{ site }}</small>
                        </template>
                        <template #selected-option="{ id, name, site }">
                          <span class="d-block">Festival {{ name }} - {{ site }}</span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="School" label-for="school-input">
                      <v-select id="school-input"
                                v-model="table.filters.school.value"
                                :loading="table.loading"
                                :options="schools" label="name"
                                :reduce="option => option.id"
                                :disabled="!festival"
                                :searchable="true"
                                :select-on-tab="true"
                                :clearable="true"
                                :filter="filterSchools"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100">
                        <template #option="{ name }">
                          {{ name.legal }}
                        </template>
                        <template #selected-option="{ name }">
                          {{ name.legal }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Instrument" label-for="instrument-input">
                      <v-select id="instrument-input"
                                v-model="table.filters.instrument.value"
                                :loading="table.loading"
                                :options="instruments" label="name"
                                :reduce="option => option.id"
                                :disabled="!festival"
                                :searchable="false"
                                :select-on-tab="true"
                                :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100"/>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Verified" label-for="verified-input">
                      <v-select id="verified-input"
                                v-model="table.filters.verified.value"
                                :options="[
                                    { label: 'Yes', value: 'true' },
                                    { label: 'No', value: 'false' },
                                    { label: 'Unset', value: 'null' }
                                ]"
                                :reduce="option => option.value"
                                :disabled="!festival"
                                :searchable="false"
                                :select-on-tab="true"
                                :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>

              <!-- Student -->
              <template #cell(student.name)="{data}">
                <span v-if="hasValue(data, 'item.student')">
                  {{ getPersonName(data.item.student) }}
                </span>
                <span v-else class="text-danger">No Student</span>
              </template>

              <!-- School -->
              <template #cell(student.school.name.legal)="{data}">
                <span v-if="hasValue(data, 'item.student.school.name')">
                  {{ data.item.student.school.name.legal }}
                </span>
                <span v-else class="text-danger">No School</span>
              </template>

              <!-- Teacher -->
              <template #cell(teacher.name)="{data}">
                  <span v-if="hasValue(data, 'item.teacher')">
                  {{ getPersonName(data.item.teacher) }}
                </span>
                <span v-else class="text-danger">No Teacher</span>
              </template>

              <!-- Instrument -->
              <template #cell(instrument.name)="{data}">
                <span v-if="hasValue(data, 'item.instrument')">
                  {{ data.item.instrument.name }}
                </span>
                <span v-else class="text-danger">No Instrument</span>
              </template>

              <!-- Verified -->
              <template #cell(verified)="{data}">
								<b-radio-group v-model="data.item.verified" :options="options.verified"
															 buttons button-variant="outline-primary"
															 @input="updateApplicationVerified(data.item.id, $event)"/>
              </template>
            </table-layout>
          </b-col>
        </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser, listFestivals, listApplications} from './queries/report-festival-applications';
import vSelect from 'vue-select';
import Fuse from 'fuse.js';
import print from '@/mixins/print.mixin';
import TableLayout from '@/components/TableLayout.vue';
import {updateApplication} from '@/graphql/queries/application';
import notify from '@/mixins/notify.mixin';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'EnsembleRoster',
  components: {
    TableLayout,
    PageLayout,
    vSelect
  },
  mixins: [ print, notify, lodashMixin, settingsMixin ],
  data() {
    return {
      user: null,
      festival: null,
      table: {
        fields: [
          {
            key: 'student.name',
            subKeys: ['student.name.first', 'student.name.last'],
            label: 'Student',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.school.name.legal',
            label: 'School',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'teacher.name',
            subKeys: ['teacher.name.first', 'teacher.name.last'],
            label: 'Teacher',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'verified',
            label: 'Verified',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
        ],
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              { text: 'Fuzzy', value: '' },
              { text: 'Exact', value: '=' },
              { text: 'Includes', value: '\'' },
              { text: 'Starts With', value: '^' },
            ]
          },
        },
        sorting: {
          comparator: (a, b) => this.compareByStudentLNFNInstrument(a, b),
          comparatorKey: 'lnfni',
          comparators: [
            { key: 'lnfni', text: 'Last Name, First Name, Instrument', comparator: (a, b) => this.compareByStudentLNFNInstrument(a, b) },
            { key: 'fnlni', text: 'First Name, Last Name, Instrument', comparator: (a, b) => this.compareByStudentFNLNInstrument(a, b) },
            { key: 'ifnln', text: 'Instrument, First Name, Last Name', comparator: (a, b) => this.compareByInstrumentStudentFNLN(a, b) },
            { key: 'ilnfn', text: 'Instrument, Last Name, First Name', comparator: (a, b) => this.compareByInstrumentStudentLNFN(a, b) }
          ]
        },
        filters: {
          school: { key: 'student.school.id', value: null },
          instrument: { key: 'instrument.id', value: null },
          verified: { key: 'verified', value: null }
        },
        items: [],
        loading: false
      },
      options: {
        festivals: {
          items: [],
          loading: true
        },
        verified: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ]
      },
      icon: 'fas fa-clipboard'
    }
  },
  computed: {
    schools() {
      return [...new Map(this.table.items.filter(item => item.student?.school).map(item => [item.student.school.id, item.student.school])).values()].sort((a, b) => a.name?.legal.localeCompare(b.name?.legal))
    },
    instruments() {
      return [...new Map(this.table.items
          .filter(item => item.instrument?.id)
          .map(item => [item.instrument.id, item.instrument])).values()
      ].sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))
    }
  },
  async mounted() {
    await this.getUser()
    this.$refs.layout.state.loading = false
    await this.listFestivals()
  },
  methods: {
    async getUser() {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const appSyncUser = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));

      this.user = {
        id: cognitoUser.attributes['custom:user_id'],
        groups: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'],
        zoneIds: appSyncUser.data?.getUser?.zoneIds || []
      }
    },
    async listFestivals() {
      this.options.festivals.loading = true
      const input = {
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        }
      }
      if(this.isZoneRep()) {
        input.filter.or = [ ]
        this.user.zoneIds.forEach(id => {
          input.filter.or.push({ festivalZoneId: { eq: id } })
        })
      }

      const response = await API.graphql(graphqlOperation(listFestivals, input));
      this.options.festivals.items = response.data.listFestivals.items.sort((a, b) => a.name.localeCompare(b.name));
      this.options.festivals.loading = false
    },
    async getApplications(festivalId) {
      if(!this.table.items.some(item => item.applicationFestivalId === festivalId)) {
        this.table.loading = true
        this.table.filters.school.value = null
        this.table.filters.instrument.value = null
        await this.listApplications(festivalId)
      }
    },
    async listApplications(festivalId, nextToken, pagedItems) {
      const items = pagedItems || []
      const input = {
        filter: {
          applicationFestivalId: { eq: festivalId },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
        nextToken: nextToken
      }
      const response = await API.graphql(graphqlOperation(listApplications, input));
      items.push(...response.data.listApplications.items);

      if(response.data.listApplications.nextToken) {
        await this.listApplications(festivalId, response.data.listApplications.nextToken, items)
      }
      else {
        this.table.items = items.map(item => ({
          ...item,
          verified: item.verified === null ? 'null' : item.verified // workaround for table filter. null value gets ignored, set it to a string value of 'null'
        })).sort((a, b) => a.student.name.last.localeCompare(b.student.name.last))

        this.table.loading = false
      }
    },
    async updateApplicationVerified(id, value) {
      try {
        await API.graphql(graphqlOperation(updateApplication, { input: { id: id, verified: value } } ));
        this.notify({ title: 'Success', text: 'Application was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Application failed to update', icon: this.icon, variant: 'danger'});
      }
    },

    async refresh() {
      await this.listFestivals()
      if(this.options.festivals.items.map(item => item.id).includes(this.festival)) {
        this.table.loading = true
        await this.listApplications(this.festival)
      }
      else {
        this.festival = null
        this.table.items = []
        this.table.filters.school.value = null
        this.table.filters.instrument.value = null
      }
    },

    /** Sort **/
    compareByStudentLNFNInstrument(a, b) {
      return a.student?.name?.last?.localeCompare(b.student?.name?.last)
          || a.student?.name?.first?.localeCompare(b.student?.name?.first)
          || a.instrument?.name?.localeCompare(b.instrument?.name)
    },
    compareByStudentFNLNInstrument(a, b) {
      return a.student?.name?.first?.localeCompare(b.student?.name?.first)
          || a.student?.name?.last?.localeCompare(b.student?.name?.last)
          || a.instrument?.name?.localeCompare(b.instrument?.name)
    },
    compareByInstrumentStudentFNLN(a, b) {
      return a.instrument?.name?.localeCompare(b.instrument?.name)
          || a.student?.name?.first?.localeCompare(b.student?.name?.first)
          || a.student?.name?.last?.localeCompare(b.student?.name?.last)
    },
    compareByInstrumentStudentLNFN(a, b) {
      return a.instrument?.name?.localeCompare(b.instrument?.name)
          || a.student?.name?.last?.localeCompare(b.student?.name?.last)
          || a.student?.name?.first?.localeCompare(b.student?.name?.first)
    },


    /** Util **/
    getPersonName(person) {
      if(person?.name) {
        return `${person.name.first} ${person.name.last}`
      }
      return ''
    },
    isZoneRep() {
      return this.user.groups.includes('ZoneRep')
    },
    isTeacher() {
      return this.user.groups.includes('Teacher')
    },

    /** Searching **/
    filterFestival(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name', 'site'],
        threshold: 0.3,
        shouldSort: true
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    filterSchools(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal'],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
  }
}
</script>

<style scoped>
  @media print
  {
    .print-title
    {
      display: block !important;
    }
  }
</style>
