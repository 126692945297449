var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Festival Applications","active":""}})]}},{key:"actions",fn:function(){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Print'),expression:"'Print'"}],attrs:{"variant":"transparent","size":"sm"},on:{"click":function($event){return _vm.print('table-container')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-print"}})],1)]},proxy:true},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(){return [_c('b-row',[_c('b-col',[_c('table-layout',{attrs:{"loading":_vm.table.loading,"items":_vm.table.items,"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false },"sorting":_vm.table.sorting,"empty-text":_vm.table.emptyText,"export-exclude-fields":[
                        'id',
                        'applicationFestivalId',
                        'instrument.id',
                        'student.id',
                        'student.school.id',
                        'teacher.id'
                ]},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"festival-input"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Festival ("),_c('span',{staticClass:"text-danger"},[_vm._v("required")]),_vm._v(") ")]},proxy:true}])},[_c('v-select',{staticClass:"w-100 mb-1",attrs:{"id":"festival-input","options":_vm.options.festivals.items,"loading":_vm.options.festivals.loading,"reduce":function (option) { return option.id; },"label":"name","select-on-tab":true,"clearable":true,"filter":_vm.filterFestival,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.getApplications},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var id = ref.id;
                var name = ref.name;
                var site = ref.site;
return [_c('span',{staticClass:"d-block"},[_vm._v("Festival "+_vm._s(name))]),_c('small',[_vm._v(_vm._s(site))])]}},{key:"selected-option",fn:function(ref){
                var id = ref.id;
                var name = ref.name;
                var site = ref.site;
return [_c('span',{staticClass:"d-block"},[_vm._v("Festival "+_vm._s(name)+" - "+_vm._s(site))])]}}]),model:{value:(_vm.festival),callback:function ($$v) {_vm.festival=$$v},expression:"festival"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"School","label-for":"school-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"school-input","loading":_vm.table.loading,"options":_vm.schools,"label":"name","reduce":function (option) { return option.id; },"disabled":!_vm.festival,"searchable":true,"select-on-tab":true,"clearable":true,"filter":_vm.filterSchools,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var name = ref.name;
return [_vm._v(" "+_vm._s(name.legal)+" ")]}},{key:"selected-option",fn:function(ref){
                var name = ref.name;
return [_vm._v(" "+_vm._s(name.legal)+" ")]}}]),model:{value:(_vm.table.filters.school.value),callback:function ($$v) {_vm.$set(_vm.table.filters.school, "value", $$v)},expression:"table.filters.school.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"instrument-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"instrument-input","loading":_vm.table.loading,"options":_vm.instruments,"label":"name","reduce":function (option) { return option.id; },"disabled":!_vm.festival,"searchable":false,"select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Verified","label-for":"verified-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"verified-input","options":[
                                    { label: 'Yes', value: 'true' },
                                    { label: 'No', value: 'false' },
                                    { label: 'Unset', value: 'null' }
                                ],"reduce":function (option) { return option.value; },"disabled":!_vm.festival,"searchable":false,"select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.verified.value),callback:function ($$v) {_vm.$set(_vm.table.filters.verified, "value", $$v)},expression:"table.filters.verified.value"}})],1)],1)],1)]},proxy:true},{key:"cell(student.name)",fn:function(ref){
                                var data = ref.data;
return [(_vm.hasValue(data, 'item.student'))?_c('span',[_vm._v(" "+_vm._s(_vm.getPersonName(data.item.student))+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Student")])]}},{key:"cell(student.school.name.legal)",fn:function(ref){
                                var data = ref.data;
return [(_vm.hasValue(data, 'item.student.school.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.student.school.name.legal)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No School")])]}},{key:"cell(teacher.name)",fn:function(ref){
                                var data = ref.data;
return [(_vm.hasValue(data, 'item.teacher'))?_c('span',[_vm._v(" "+_vm._s(_vm.getPersonName(data.item.teacher))+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Teacher")])]}},{key:"cell(instrument.name)",fn:function(ref){
                                var data = ref.data;
return [(_vm.hasValue(data, 'item.instrument'))?_c('span',[_vm._v(" "+_vm._s(data.item.instrument.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Instrument")])]}},{key:"cell(verified)",fn:function(ref){
                                var data = ref.data;
return [_c('b-radio-group',{attrs:{"options":_vm.options.verified,"buttons":"","button-variant":"outline-primary"},on:{"input":function($event){return _vm.updateApplicationVerified(data.item.id, $event)}},model:{value:(data.item.verified),callback:function ($$v) {_vm.$set(data.item, "verified", $$v)},expression:"data.item.verified"}})]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }